<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? "AJOUTER LE TAUX DE" : "METTRE À JOUR LE TAUX DE" }} COMMISSION</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings">
      <div class="p-6">
        <!-- NAME -->
        <vs-input disabled  label="Nom" v-model="first_name" class="mt-5 w-full" name="first_name" v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('first_name')">{{ errors.first('first_name') }}</span>

        <!-- Prenons-->
        <vs-input disabled   label="Prénon" v-model="last_name" class="mt-5 w-full" name="last_name" v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>

        <!-- Taux de commissions-->
        <vs-input   label="Taux de commissions" v-model="commissionRate" class="mt-5 w-full" name="commissionRate" v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('commissionRate')">{{ errors.first('commissionRate') }}</span>

<!--        &lt;!&ndash; Date de naissance&ndash;&gt;-->
<!--        <p hidden class="pt-4">Date de naissance </p>-->
<!--        <flat-pickr hidden label="Date" v-model="date"  name="date" class="mt-5 w-full" v-validate="'required'"/>-->
<!--        <span hidden class="text-danger text-sm" v-show="errors.has('date')">{{ errors.first('date') }}</span>-->
<!--        &lt;!&ndash; Telephone&ndash;&gt;-->

<!--        <p  hidden class="pt-4">Téléphone </p>-->
<!--        <vue-tel-input hidden-->
<!--          v-bind="bindProps"-->
<!--          v-on:country-changed="countryChanged"-->
<!--          v-model="phone"-->
<!--          data-vv-validate-on="blur"-->
<!--          v-validate="'required|min:8|max:8'"-->
<!--        >-->
<!--        </vue-tel-input>-->

<!--        <span hidden class="text-danger text-sm" v-show="errors.has('phone')">{{ errors.first('phone') }}</span>-->

<!--        &lt;!&ndash;EMAIL&ndash;&gt;-->
<!--        <vs-input hidden v-validate="'required|email'" label="Email" name="email" type="email" v-model="email" class="w-full mt-6" />-->
<!--        <span hidden class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>-->

        <!--        &lt;!&ndash; Pays &ndash;&gt;-->

        <!--        <vs-select v-model="country" autocomplete label="Pays" class="mt-5 w-full" name="country" v-validate="'required'">-->
        <!--          <vs-select-item :key="item" :value="item.id" :text="item.name" v-for="item in countries" />-->
        <!--        </vs-select>-->
        <!--        <span class="text-danger text-sm" v-show="errors.has('country')">{{ errors.first('country') }}</span>-->

        <!-- Ville -->

<!--        <vs-select hidden v-model="city" autocomplete label="Ville" class="mt-5 w-full" name="city" v-validate="'required'">-->
<!--          <vs-select-item :key="item" :value="item.id" :text="item.name" v-for="item in cities" />-->
<!--        </vs-select>-->
<!--        <span hidden class="text-danger text-sm" v-show="errors.has('city')">{{ errors.first('city') }}</span>-->


      </div>

    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button  class="mr-6" @click="submitData" :disabled="!isFormValid" >Soumettre</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Annuler</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

// For custom error message
import { Validator } from 'vee-validate'
const dict = {
  custom: {
    first_name: {
      required: 'Le champ nom est obligatoire'
    },
    last_name: {
      required: 'Le champ prénoms est obligatoire'
    },
    commissionRate: {
      required: 'Le champ Taux de commissions est obligatoire'
    },
    // birthDate: {
    //   required: 'Le champ date de naissance est obligatoire'
    // },
    // email: {
    //   required: 'Le champ email est obligatoire'
    // },
    // date: {
    //   required: 'Le champ date est obligatoire'
    // },
    // phone: {
    //   required: 'Le champ telephone est obligatoire'
    // } ,
    // country: {
    //   required: 'Le champ pays est obligatoire'
    // } ,
    // city: {
    //   required: 'Le champ ville est obligatoire'
    // }
  }
}

// register custom messages
Validator.localize('en', dict)

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    }
  },

  watch: {

    city(value){
      if (value){
        let parent=value
        this.input.recipients = []
        this.$http.post('recipient_filter/', {parent})
          .then((response) => {
            // console.log("test : ", response.data)
            this.recipients = response.data})
          .catch((error) => { console.log(error) })
      }

    },
    isSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      }else {
        let { last_name, id, first_name,commissionRate} = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.last_name = last_name
        this.first_name  = first_name
        this.commissionRate  = commissionRate
        // this.city= city.id
        // this.email  = email
        // this.date = birthDate
        // this.phone=phoneNumber
        this.initValues()
      }
    }
  },
  data() {
    return {
      date: null,
      dataId: null,
      last_name : "",
      city : "",
      first_name : "",
      email :"",
      username:"",
      country:"",
      countryID:"",
      countries:[],
      cities:[],
      phone: '',
      bindProps: {
        mode: "international",
        placeholder: "Telephone",
        required: true,
        enabledFlags: true,
        autocomplete: "off",
        name: "telephone",
        maxLen: 12,
        validCharactersOnly:true,
        inputOptions: {
          showDialCode: true
        }
      },

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    }
  },

  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
          this.$validator.reset()
          this.initValues()
        }
      }
    },
    isFormValid() {
      return !this.errors.any() &&
        this.last_name &&
        this.first_name  &&
        this.commissionRate
        // this.email  &&
        // this.date  &&
        // // this.country  &&
        // this.city  &&
        // this.phone
    },

  },
  created() {
    this.$http.get("countries")
      .then((response) => { this.countries = response.data })
      .catch((error) => { console.log(error) })

    this.$http.get("cities")
      .then((response) => { this.cities = response.data })
      .catch((error) => { console.log(error) })

  },
  methods: {
    initValues() {
      if(this.data.id) return
      this.last_name =""
      this.first_name  =""
      this.commissionRate  =""
      // this.email  =""
      // // this.country  =""
      // this.date =""
      // this.phone =""
      // this.city =""
    },

    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          const obj = {
            id: this.dataId,
            commissionRate: this.commissionRate,
            // last_name:this.last_name,
            // first_name:this.first_name,
            // username: this.last_name +''+this.first_name,
            // birthDate: this.date,
            // phoneNumber: this.phone,
            // // country: this.country ,
            // email: this.email ,
            // city: this.city ,
          }

          if(this.dataId !== null && this.dataId >= 0) {
            this.$vs.loading()
            this.$store.dispatch("dataListContributors/rate_update", obj)
            // this.$store.dispatch("dataListContributors/fetchContributors")
              .then(() => {
                this.$vs.notify({
                  title: 'MODIFICATION',
                  text: 'Taux de commissions modifié',
                  color: 'success',
                  position:'top-center'
                })
                this.$vs.loading.close() })
              .catch(err => { console.error(err) })


          }else{
            delete obj.id
            obj.popularity = 0
            this.$vs.loading()
            this.$store.dispatch("dataListContributors/addContributors", obj)
              .then(() => {
                this.$store.dispatch("dataListContributors/fetchContributors")
                this.$vs.loading.close()
                this.$vs.notify({
                  title: 'ENREGISTREMENT',
                  text: 'Enregistrement effectué',
                  color: 'success',
                  position:'top-right'
                })
              })
              .catch((error) => {
                const clefs = Object.keys(error.response.data)
                for (let i = 0; i < clefs.length; i++) {
                  const item = clefs[i]
                  // let libelle = ''

                  if (item === 'username') {
                    // libelle = "Nom d'utilisateur"
                    this.$vs.notify({
                      time:4000,
                      title: 'ATTENTION',
                      text: "Nom d'utilisateur déjà utilisé",
                      color: 'warning',
                      position:'top-center'
                    })
                  } else if (item === 'email') {
                    // libelle = 'Email'
                    this.$vs.notify({
                      time:4000,
                      title: 'ATTENTION',
                      text: "L'Email déjà utilisé ",
                      color: 'warning',
                      position:'top-center'
                    })
                  } else if (item === 'phoneNumber') {
                    // libelle = 'Teléphone'
                    this.$vs.notify({
                      time:4000,
                      title: 'ATTENTION',
                      text: "Le Numéro de téléphone déjà utilisé ",
                      color: 'warning',
                      position:'top-center'
                    })
                  }

                }
                this.$vs.loading.close()
                this.$vs.notify({
                  time:8000,
                  title: 'ENREGISTREMENT',
                  text: "L'enregistrement a écchoué",
                  color: 'danger',
                  position:'top-right'
                })
              })

          }
          this.$emit('closeSidebar')
          this.initValues()
        }
      })
    },
  },
  components: {
    VuePerfectScrollbar,flatPickr
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
